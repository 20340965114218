<script>
  import { fetchUpdatePolicy, fetchCreatePolicy } from "./api";
  import { get, range, filter, clone } from "lodash-es";
  import { parse as parsems } from "duration-fns";
  import DayTimesWeekChart from "$components/daytimes/DayTimesWeekChart.svelte";

  export let policy = null;
  export let readonly = false;

  let submittable = false;
  let submitting = false;

  // react only if in an unchanged state
  //$: editingPolicy = policy && !submittable ? {...policy} : editingPolicy;
  let editingPolicy = null;

  // save the first one we get
  //$: if(!!policy && !editingPolicy) editingPolicy  = {...policy};
  $: if (!!policy && (!editingPolicy || !submittable))
    editingPolicy = { ...policy };

  //$: console.log("editingPolicy=", editingPolicy);
  //$: console.log("issue=", issue, "disabled=", disabled);

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    await fetchUpdatePolicy(editingPolicy, formData);

    // clear the state
    submitting = submittable = false;
    editingPolicy = null;
  }

  const times = [
    0.25 * 60 * 60 * 1000,
    0.5 * 60 * 60 * 1000,
    0.75 * 60 * 60 * 1000,
    1.0 * 60 * 60 * 1000,
    1.25 * 60 * 60 * 1000,
    1.5 * 60 * 60 * 1000,
    1.75 * 60 * 60 * 1000,
    2.0 * 60 * 60 * 1000,
    2.5 * 60 * 60 * 1000,
    3.0 * 60 * 60 * 1000,
    3.5 * 60 * 60 * 1000,
    4.0 * 60 * 60 * 1000,
    5.0 * 60 * 60 * 1000,
    6.0 * 60 * 60 * 1000,
    7.0 * 60 * 60 * 1000,
    8.0 * 60 * 60 * 1000,
    10.0 * 60 * 60 * 1000,
    12.0 * 60 * 60 * 1000,
    14.0 * 60 * 60 * 1000,
    16.0 * 60 * 60 * 1000,
    18.0 * 60 * 60 * 1000,
    20.0 * 60 * 60 * 1000,
    22.0 * 60 * 60 * 1000,
    24.0 * 60 * 60 * 1000,
    36.0 * 60 * 60 * 1000,
    48.0 * 60 * 60 * 1000,
  ];

  function calculateMaxDuration(min) {
    const max = 24 * 60 * 60 * 1000;
    const items = [];
    let i = min;

    items.push(min);

    for (let i of times.filter((time) => time > min && time < min * 2)) {
      items.push(i);
      //min = i;
    }

    // first 8 steps then on the hour
    for (i = min * 2; i < max; i += min) {
      if (i <= 8 * min) items.push(i);
      else if (i % (60 * 60 * 1000) === 0) items.push(i);
    }

    return items;
  }

  function toISOString(ms) {
    const parsed = parsems(ms);
    return (
      "PT" +
      (parsed.hours > 0 ? parsed.hours + "H" : "") +
      (parsed.minutes > 0 ? parsed.minutes + "M" : "")
    );
  }

  function formatTime(ms) {
    const parsed = parsems(ms);
    return `${parsed.hours}:${parsed.minutes.toString().padStart(2, "0")}`;
  }
</script>

{#if editingPolicy}
  <form on:change={change} on:input={change} on:submit={submit}>
    <header>
      <h1>Times & Duration</h1>
    </header>

    <fieldset disabled={readonly && "disabled"}>
      <ul>
        <li>
          <label for="policy-valid-cancel">Cancel</label>
          <select
            name="permit.quota.reclaimable"
            value={get(editingPolicy, "permits.reclaimable", true) + ""}
          >
            <option value="true">any time</option>
            <option value="false">before start only</option>
          </select>
        </li>
        <li>
          <label for="policy-valid-grace">Grace</label>
          <select
            name="permit.valid.grace"
            value={get(editingPolicy, "overflow.permit")}
          >
            <option value="PT1H">1:00</option>
            <option value="PT45M">0:45</option>
            <option value="PT30M">0:30</option>
            <option value="PT20M">0:20</option>
            <option value="PT15M">0:15</option>
            <option value="PT2H">2:00</option>
            <option value="PT3H">3:00</option>
            <option value="PT12H">12:00</option>
            <option value="PT24H">24:00</option>
            <option value="PT0S">disabled</option>
          </select>
        </li>
      </ul>
    </fieldset>

    <fieldset disabled={readonly && "disabled"}>
      <p>In advance:</p>
      <ul>
        <li>
          <label for="policy-valid-future-min">Min</label>
          <select
            id="policy-valid-future-min"
            name="permit.valid.min.future.min"
            value={get(editingPolicy, "valid.min.future.min")}
          >
            <option value="PT{14 * 24}H">at least 14 days</option>
            <option value="PT{10 * 24}H">at least 10 days</option>
            <option value="PT{9 * 24}H">at least 9 days</option>
            <option value="PT{8 * 24}H">at least 8 days</option>
            <option value="PT{7 * 24}H">at least 7 days</option>
            <option value="PT{6 * 24}H">at least 6 days</option>
            <option value="PT{5 * 24}H">at least 5 days</option>
            <option value="PT100H">at least 100 hours</option>
            <option value="PT{4 * 24}H">at least 4 days</option>
            <option value="PT72H">at least 72 hours</option>
            <option value="PT48H">at least 48 hours</option>
            <option value="PT36H">at least 36 hours</option>
            <option value="PT24H">at least 24 hours</option>
            <option value="PT18H">at least 18 hours</option>
            <option value="PT12H">at least 12 hours</option>
            <option value="PT6H">at least 6 hours</option>
            <option value="PT2H">at least 2 hours</option>
            <option value="PT4H">at least 4 hours</option>
            <option value="PT1H">at least 1 hour</option>
            <option value="PT30M">at 30 minutes</option>
            <option value="PT0S">none required</option>
          </select>
        </li>
        <li>
          <label for="policy-valid-future-max">Max</label>
          <select
            id="policy-valid-future-max"
            name="permit.valid.min.future.max"
            value={get(editingPolicy, "valid.min.future.max")}
          >
            <option value="PT2H">up to 2 hours</option>
            <option value="PT12H">up to 12 hours</option>
            <option value="PT24H">up to 24 hours</option>
            <option value="PT{1.5 * 24}H">up to 36 hours</option>
            <option value="PT{2 * 24}H">up to 48 hours</option>
            <option value="PT{3 * 24}H">up to 72 hours</option>
            <option value="PT{4 * 24}H">up to 4 days</option>
            <option value="PT100H">up to 100 hours</option>
            <option value="PT{5 * 24}H">up to 5 days</option>
            <option value="PT{6 * 24}H">up to 6 days</option>
            <option value="PT{7 * 24}H">up to 7 days</option>
            <option value="PT{8 * 24}H">up to 8 days</option>
            <option value="PT{9 * 24}H">up to 9 days</option>
            <option value="PT{10 * 24}H">up to 10 days</option>
            <option value="PT{11 * 24}H">up to 11 days</option>
            <option value="PT{12 * 24}H">up to 12 days</option>
            <option value="PT{13 * 24}H">up to 13 days</option>
            <option value="PT{14 * 24}H">up to 14 days</option>
            <option value="PT{30 * 24}H">up to 30 days</option>
            <option value="PT{45 * 24}H">up to 45 days</option>
            <option value="PT{60 * 24}H">up to 60 days</option>
            <option value="PT{90 * 24}H">up to 90 days</option>
            <option value="PT{120 * 24}H">up to 120 days</option>
            <option value="PT{180 * 24}H">up to 180 days</option>
            <option value="PT{365 * 24}H">up to 365 days</option>
            <option value="PT0S">start now only</option>
          </select>
        </li>
      </ul>
    </fieldset>
    <fieldset disabled={readonly && "disabled"}>
      <p>Duration:</p>
      <ul>
        <li>
          <label for="policy-valid-duration-min">Min</label>
          <select
            id="policy-valid-duration-min"
            name="permit.valid.duration.min"
            value={get(editingPolicy, "valid.duration.min") ||
              get(editingPolicy, "valid.duration")}
            ><option value="PT15M">0:15</option><option value="PT30M"
              >0:30</option
            ><option value="PT45M">0:45</option><option value="PT1H"
              >1:00</option
            ><option value="PT2H">2:00</option><option value="PT3H">3:00</option
            ><option value="PT4H">4:00</option><option value="PT5H">5:00</option
            ><option value="PT6H">6:00</option><option value="PT7H">7:00</option
            ><option value="PT8H">8:00</option><option value="PT9H">9:00</option
            ><option value="PT10H">10:00</option><option value="PT11H"
              >11:00</option
            ><option value="PT12H">12:00</option><option value="PT13H"
              >13:00</option
            ><option value="PT14H">14:00</option>
            <option value="PT15H">15:00</option>
            <option value="PT16H">16:00</option>
            <option value="PT17H">17:00</option>
            <option value="PT18H">18:00</option>
            <option value="PT19H">19:00</option>
            <option value="PT20H">20:00</option>
            <option value="PT24H">24:00</option>
            <option value="PT36H">36:00</option>
            <option value="PT48H">48:00</option>
            <option value="PT72H">72:00</option>
            <option value="PT80H">80:00</option>
            <option value="PT90H">90:00</option>
            <option value="PT96H">96:00</option>
            <option value="PT100H">100:00</option>
            <option value="PT120H">5 days</option>
            <option value="PT168H">7 days</option>
            <option value="PT336H">14 days</option>
            <option value="PT{15 * 24}H">15 days</option>
            <option value="PT{28 * 24}H">28 days</option>
            <option value="PT720H">30 days</option>
            <option value="P1M">calendar month</option>
            <option value="PT{45 * 24}H">45 days</option>
            <option value="PT1440H">60 days</option>
            <option value="PT{24 * 70}H">70 days</option>
            <option value="PT2160H">90 days</option>
            <option value="PT4320H">180 days</option>
            <option value="PT{365 * 24}H">365 days</option>
          </select>
        </li>
        <li>
          <label for="policy-valid-duration-max">Max</label>
          <select
            id="policy-valid-duration-max"
            name="permit.valid.duration.max"
            value={get(editingPolicy, "valid.duration.max") ||
              get(editingPolicy, "valid.duration")}
            ><option value="PT15M">0:15</option><option value="PT30M"
              >0:30</option
            ><option value="PT45M">0:45</option><option value="PT1H"
              >1:00</option
            ><option value="PT90M">1:30</option><option value="PT2H"
              >2:00</option
            ><option value="PT3H">3:00</option><option value="PT4H">4:00</option
            ><option value="PT5H">5:00</option><option value="PT6H">6:00</option
            ><option value="PT7H">7:00</option><option value="PT8H">8:00</option
            ><option value="PT9H">9:00</option><option value="PT10H"
              >10:00</option
            ><option value="PT12H">12:00</option><option value="PT13H"
              >13:00</option
            ><option value="PT14H">14:00</option><option value="PT15H"
              >15:00</option
            >
            <option value="PT16H">16:00</option>
            <option value="PT17H">17:00</option>
            <option value="PT18H">18:00</option>
            <option value="PT19H">19:00</option>
            <option value="PT20H">20:00</option>
            <option value="PT21H">21:00</option>
            <option value="PT22H">22:00</option>
            <option value="PT23H">23:00</option>
            <option value="PT24H">24:00</option>
            <option value="PT25H">25:00</option>
            <option value="PT27H">27:00</option>
            <option value="PT36H">36:00</option>
            <option value="PT40H">40:00</option>
            <option value="PT48H">48:00</option>
            <option value="PT72H">72:00</option>
            <option value="PT80H">80:00</option>
            <option value="PT90H">90:00</option>
            <option value="PT96H">96:00</option>
            <option value="PT100H">100:00</option>
            <option value="PT120H">5 days</option>
            <option value="PT144H">6 days</option>
            <option value="PT168H">7 days</option>
            <option value="PT{8 * 24}H">8 days</option>
            <option value="PT{9 * 24}H">9 days</option>
            <option value="PT240H">10 days</option>
            <option value="PT264H">11 days</option>
            <option value="PT288H">12 days</option>
            <option value="PT312H">13 days</option>
            <option value="PT336H">14 days</option>
            <option value="PT360H">15 days</option>
            <option value="PT432H">18 days</option>
            <option value="PT{20 * 24}H">20 days</option>
            <option value="PT{28 * 24}H">28 days</option>
            <option value="PT720H">30 days</option>
            <option value="PT{31 * 24}H">31 days</option>
            <option value="P1M">calendar month</option>
            <option value="PT{45 * 24}H">45 days</option>
            <option value="PT1440H">60 days</option>
            <option value="PT{24 * 70}H">70 days</option>
            <option value="PT2160H">90 days</option>
            <option value="PT4320H">180 days</option>
            <option value="PT{365 * 24}H">365 days</option>
          </select>
        </li>
      </ul>
    </fieldset>

    <fieldset disabled={readonly && "disabled"}>
      <p>Start time:</p>
      <ul>
        <li>
          <label for="policy-valid-start-min">Min</label>
          <select
            id="policy-valid-start-min"
            name="permit.start.minimum"
            value={get(editingPolicy, "start.time.min") ||
              get(editingPolicy, "start.time") ||
              ""}
          >
            <option value="">automatic</option>
            {#each [...Array(24).keys()] as hr}
              {#each [0, 15, 30, 45] as min}
                <option
                  value="{hr >= 10 ? hr : '0' + hr}:{min < 10
                    ? '0' + min
                    : min}:00"
                  >{hr % 12 == 0 ? 12 : hr % 12}:{min < 10 ? "0" + min : min}
                  {hr >= 12 ? "PM" : "AM"}</option
                >
              {/each}
            {/each}
          </select>
        </li>
        <li>
          <label for="policy-valid-start-max">Max</label>
          <select
            id="policy-valid-start-max"
            name="permit.start.maximum"
            value={get(editingPolicy, "start.time.max") ||
              get(editingPolicy, "start.time") ||
              ""}
          >
            <option value="">automatic</option>
            {#each [...Array(24).keys()] as hr}
              {#each [0, 15, 30, 45] as min}
                <option
                  value="{hr >= 10 ? hr : '0' + hr}:{min < 10
                    ? '0' + min
                    : min}:00"
                  >{hr % 12 == 0 ? 12 : hr % 12}:{min < 10 ? "0" + min : min}
                  {hr >= 12 ? "PM" : "AM"}</option
                >
              {/each}
            {/each}
          </select>
        </li>
        <li>
          <label for="policy-valid-start-daystimes">Days/times</label>
          <ul>
            {#each Object.entries(editingPolicy.valid.min.daytime) as [iso, text]}
              <li><time datetime={iso}>{text}</time></li>
            {/each}
            <li>
              <DayTimesWeekChart
                values={Object.keys(editingPolicy.valid.min.daytime)}
              />
            </li>
          </ul>
        </li>
        <li>
          <label for="policy-valid-start-retroactive">Retroactive</label>
          <input
            id="policy-valid-start-retroactive"
            type="text"
            readonly
            value={get(editingPolicy, "valid.min.past")}
          />
        </li>
      </ul>
    </fieldset>

    <fieldset disabled={readonly && "disabled"}>
      <p>End time:</p>
      <ul>
        <li>
          <label for="policy-valid-end-min">Min</label>
          <select
            id="policy-valid-end-min"
            name="permit.end.minimum"
            value={get(editingPolicy, "end.time.min") ||
              get(editingPolicy, "end.time") ||
              ""}
          >
            <option value="">automatic</option>
            {#each [...Array(24).keys()] as hr}
              {#each [0, 15, 30, 45] as min}
                <option
                  value="{hr >= 10 ? hr : '0' + hr}:{min < 10
                    ? '0' + min
                    : min}:00"
                  >{hr % 12 == 0 ? 12 : hr % 12}:{min < 10 ? "0" + min : min}
                  {hr >= 12 ? "PM" : "AM"}</option
                >
              {/each}
            {/each}
            <option value="23:59">11:59 PM</option>
          </select>
          <!-- <input id="policy-valid-end-min" type="time" name="permit.end.minimum" value={get(editingPolicy, "end.time.min") || get(editingPolicy, "end.time") || ""} /> -->
        </li>
        <li>
          <label for="policy-valid-end-max">Max</label>
          <select
            id="policy-valid-end-max"
            name="permit.end.maximum"
            value={get(editingPolicy, "end.time.max") ||
              get(editingPolicy, "end.time") ||
              ""}
          >
            <option value="">automatic</option>
            {#each [...Array(24).keys()] as hr}
              {#each [0, 15, 30, 45] as min}
                <option
                  value="{hr >= 10 ? hr : '0' + hr}:{min < 10
                    ? '0' + min
                    : min}:00"
                  >{hr % 12 == 0 ? 12 : hr % 12}:{min < 10 ? "0" + min : min}
                  {hr >= 12 ? "PM" : "AM"}</option
                >
              {/each}
            {/each}
            <option value="23:59">11:59 PM</option>
          </select>
          <!-- <input id="policy-valid-end-max" type="time" name="permit.end.maximum" value={get(editingPolicy, "end.time.max") || get(editingPolicy, "end.time") || ""} /> -->
        </li>
        <li>
          <label for="policy-valid-end-daystimes">Days/times</label>
          <ul>
            {#each Object.entries(editingPolicy.valid.max.daytime) as [iso, text]}
              <li><time datetime={iso}>{text}</time></li>
            {/each}
            <li>
              <DayTimesWeekChart
                values={Object.keys(editingPolicy.valid.max.daytime)}
              />
            </li>
          </ul>
        </li>
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
{/if}
