<script>
  import EditPropertyBilling from "$components/EditPropertyBilling.svelte";
  import Notifications from "$components/Notifications.svelte";
  import { authorized, notify, token } from "$stores";
  import { get } from "lodash-es";
  import { fetchUpdateProperty } from "./api";
  import DeleteProperty from "./DeleteProperty.svelte";
  import EditPropertyImages from "./EditPropertyImages.svelte";
  import Users from "./Users.svelte";

  export let property = null;

  let editing = null;

  let submittable = false;
  let submitting = false;

  // save the first one we get
  $: if (!!property && (!submittable || !editing)) editing = { ...property };

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable || !editing) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    const newPolicy = await fetchUpdateProperty(editing, formData);

    submitting = submittable = false;
    editing = null;
  }
</script>

{#if editing}
  <form
    on:change={change}
    on:input={change}
    on:submit={submit}
    class="edit property"
  >
    <header>
      <h1>Basic Settings</h1>
    </header>

    <fieldset>
      <ul>
        <li>
          <label for="property-name">Name</label><input
            id="property-name"
            name="name"
            type="text"
            maxlength="50"
            value={get(editing, "name", "")}
            required
            placeholder="required"
          />
        </li>
        <li>
          <label for="property-format">Type</label>
          <select
            id="property-format"
            name="format"
            value={get(editing, "format", "")}
          >
            <option value="multifamily">multifamily</option>
            <option value="hoa">condo/hoa</option>
            <option value="commercial">commercial</option>
            <option value="demo">demo</option>
          </select>
        </li>
        <li>
          <label for="property-reports">Reports</label>
          <select
            id="property-reports"
            name="reports.enabled"
            value={!!get(editing, "reports.enabled", false) + ""}
          >
            <option value="true">yes</option>
            <option value="false">no</option>
          </select>
        </li>
        <li>
          <label for="property-management">Managed by</label><input
            id="property-management"
            name="management"
            type="text"
            value={get(editing, "management", "")}
            required
            placeholder="required"
          />
        </li>
        <li>
          <label for="property-rep">Rep</label><input
            id="property-rep"
            name="rep"
            type="text"
            value={get(editing, "rep.email", "")}
            required
            placeholder="required"
          />
        </li>
      </ul>
    </fieldset>

    <fieldset>
      <p>Address:</p>
      <ul>
        <li>
          <label for="property-address-street">Street</label><input
            id="property-address-street"
            name="address.street"
            type="text"
            maxlength="150"
            required
            value={get(editing, "address.street", "")}
            placeholder="required"
          />
        </li>
        <li>
          <label for="property-address-city">City</label><input
            id="property-address-city"
            name="address.city"
            type="text"
            maxlength="50"
            required
            value={get(editing, "address.city", "")}
            placeholder="required"
          />
        </li>
        <li>
          <label for="property-address-state">State</label><input
            id="property-address-state"
            name="address.state"
            type="text"
            maxlength="2"
            required
            value={get(editing, "address.state", "")}
            placeholder="required"
          />
        </li>
        <li>
          <label for="property-address-postal">Postal</label><input
            id="property-address-postal"
            name="address.postal"
            type="text"
            maxlength="25"
            required
            value={get(editing, "address.postal", "")}
            placeholder="required"
          />
        </li>
      </ul>
    </fieldset>

    <fieldset>
      <p>Management office contact (public):</p>
      <ul>
        <li>
          <label for="property-contact-name">Name</label><input
            id="property-contact-name"
            name="contact.name"
            type="text"
            value={get(editing, "contact.name", "")}
            placeholder="optional"
          />
        </li>
        <li>
          <label for="property-contact-email">Email</label><input
            id="property-contact-email"
            name="contact.email"
            type="email"
            value={get(editing, "contact.email", "")}
            placeholder="optional"
          />
        </li>
        <li>
          <label for="property-contact-tel">Phone</label><input
            id="property-contact-tel"
            name="contact.tel"
            type="tel"
            value={get(editing, "contact.tel", "")}
            placeholder="optional"
          />
        </li>
      </ul>
    </fieldset>

    <fieldset>
      <ul>
        <li>
          <label for="property-crm-service">Integration</label><select
            id="property-crm-service"
            name="crm.service"
            value={get(editing, "crm.service", "") + ""}
          >
            <option value="">no</option>
            <option value="realpage-ode">RealPage ODE</option>
            <option value="yardi">Yardi</option>
            <option value="entrata">Entrata</option>
          </select>
        </li>
        <li>
          <label for="property-vehicles">Vehicles</label>
          <select
            id="property-vehicles"
            name="vehicles.enabled"
            value={get(editing, "vehicles.enabled", false) + ""}
            disabled
          >
            <option value="true">enabled</option>
            <option value="false">disabled</option>
          </select>
        </li>
        <li>
          <label>Timezone</label>
          <input type="text" readonly value={get(editing, "timezone")} />
        </li>
        <!-- <li>
                <label>Alerts</label>
                <a href="https://boss.parkingboss.com/locations/{editing.id}" target="_blank" rel="noreferrer">open in new tab</a>
            </li> -->
        <li>
          <label>PF Exempt</label>
          <a
            href="https://boss.parkingboss.com/locations/{editing.id}/permits/fees/exempt#access_token={$token}"
            target="_blank"
            rel="noreferrer">open in new tab</a
          >
        </li>
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
  <EditPropertyImages {property} />
  <EditPropertyBilling {property} />
  <!-- <EditPropertyPayments property={property} /> -->
  <section>
    <header>
      <h1>Users</h1>
    </header>
    <Users {property} authorized={$authorized} />
  </section>
  <section>
    <header>
      <h1>Alerts</h1>
    </header>
    <Notifications {property} items={$notify} />
  </section>
  <DeleteProperty {property} />
{/if}
