<script>
  import { fetchCreatePolicyPrice as update } from "./api";
  import { normalize, parse as parsems } from "duration-fns";
  import { durationMS, formatDuration } from "./util/datetime";
  import { units, policies } from "./stores";
  import { addDays, format } from "date-fns";

  export let policy = null;

  let maximum = false;
  let period = "PT1H";
  let freePeriod = "";
  let hourly = true;
  let daily = false;
  let applies = "";
  let days = false;
  let price = 5.0;
  let contingent = false;
  let validDurationMin = "";
  let group = "vehicle";
  let subjects = false;

  $: principal = group == "principal";

  function reset() {
    maximum = false;
    period = "PT1H";
    freePeriod = "";
    hourly = true;
    daily = false;
    applies = "";
    days = false;
    subjects = true;
    subjects = false;
    price = 5.0;
    contingent = false;
    validDurationMin = "";
    (group = true) && (group = "vehicle");
  }

  let submittable = false;
  let submitting = false;

  const hourMS = 60 * 60 * 1000;

  $: hasPrice = price != null && price !== "";

  $: validDurationMaxValues =
    validDurationMin && calculateMaxDuration(durationMS(validDurationMin));

  const times = [
    0.25 * 60 * 60 * 1000,
    0.5 * 60 * 60 * 1000,
    0.75 * 60 * 60 * 1000,
    1.0 * 60 * 60 * 1000,
    1.25 * 60 * 60 * 1000,
    1.5 * 60 * 60 * 1000,
    1.75 * 60 * 60 * 1000,
    2.0 * 60 * 60 * 1000,
    2.5 * 60 * 60 * 1000,
    3.0 * 60 * 60 * 1000,
    3.5 * 60 * 60 * 1000,
    4.0 * 60 * 60 * 1000,
    5.0 * 60 * 60 * 1000,
    6.0 * 60 * 60 * 1000,
    7.0 * 60 * 60 * 1000,
    8.0 * 60 * 60 * 1000,
    10.0 * 60 * 60 * 1000,
    12.0 * 60 * 60 * 1000,
    14.0 * 60 * 60 * 1000,
    16.0 * 60 * 60 * 1000,
    18.0 * 60 * 60 * 1000,
    20.0 * 60 * 60 * 1000,
    22.0 * 60 * 60 * 1000,
    24.0 * 60 * 60 * 1000,
    30.0 * 60 * 60 * 1000,
    36.0 * 60 * 60 * 1000,
    48.0 * 60 * 60 * 1000,
    60.0 * 60 * 60 * 1000,
    72.0 * 60 * 60 * 1000,
    4 * 24 * 60 * 60 * 1000,
    7 * 24 * 60 * 60 * 1000,
    14 * 24 * 60 * 60 * 1000,
    30 * 24 * 60 * 60 * 1000,
    45 * 24 * 60 * 60 * 1000,
    60 * 24 * 60 * 60 * 1000,
    90 * 24 * 60 * 60 * 1000,
    120 * 24 * 60 * 60 * 1000,
    150 * 24 * 60 * 60 * 1000,
    180 * 24 * 60 * 60 * 1000,
  ];

  function calculateMaxDuration(min) {
    const max = 24 * 60 * 60 * 1000;
    const items = [];
    let i = min;

    items.push(min);

    for (let i of times.filter((time) => time > min)) {
      items.push(i);
      //min = i;
    }

    // first 8 steps then on the hour
    for (i = min * 2; i < max; i += min) {
      if (i <= 8 * min) items.push(i);
      else if (i % (60 * 60 * 1000) === 0) items.push(i);
    }

    return items;
  }

  function change(e) {
    submittable = e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (!policy || submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    await update(policy, formData);

    submitting = submittable = false;

    reset();

    //form.reset();
  }

  function formatTime(ms) {
    const parsed = normalize(parsems(ms));
    console.log("parsed=", parsed);
    if (parsed.days > 3) {
      return `${parsed.days} days, ${parsed.hours}:${parsed.minutes
        .toString()
        .padStart(2, "0")}`;
    }
    return `${parsed.days * 24 + parsed.hours}:${parsed.minutes
      .toString()
      .padStart(2, "0")}`;
  }
</script>

{#if policy}
  <form on:change={change} on:input={change} on:submit={submit}>
    <fieldset>
      <ul>
        <li>
          <label for="new-price-value">Price</label><input
            id="new-price-value"
            name="amount.dollars"
            type="number"
            min="0"
            bind:value={price}
            step="0.01"
            required
            placeholder="required"
          />
        </li>
        {#if hasPrice && price == 0}
          <li>
            <label for="new-price-time">Free time</label>
            <select
              id="new-price-freetime"
              name="period"
              bind:value={freePeriod}
            >
              <option value="">all</option>
              <option value="PT30M">30 minutes</option>
              <option value="PT1H">1 hour</option>
              <option value="PT90M">90 minutes</option>
              <option value="PT2H">2 hours</option>
              <option value="PT3H">3 hours</option>
              <option value="PT4H">4 hours</option>
              <option value="PT5H">5 hours</option>
              <option value="PT6H">6 hours</option>
              <option value="PT8H">8 hours</option>
              <option value="PT10H">10 hours</option>
              <option value="PT12H">12 hours</option>
              <option value="PT14H">14 hours</option>
              <option value="PT16H">16 hours</option>
              <option value="PT18H">18 hours</option>
              <option value="PT20H">20 hours</option>
              <option value="PT21H">21 hours</option>
              <option value="PT22H">22 hours</option>
              <option value="PT23H">23 hours</option>
              <option value="PT24H">24 hours</option>
              <option value="PT25H">25 hours</option>
              <option value="PT28H">28 hours</option>
              <option value="PT30H">30 hours</option>
              <option value="PT33H">33 hours</option>
              <option value="PT36H">36 hours</option>
              <option value="PT40H">40 hours</option>
              <option value="PT42H">42 hours</option>
              <option value="PT48H">48 hours</option>
              <option value="PT49H">49 hours</option>
              <option value="PT50H">50 hours</option>
              <option value="PT55H">55 hours</option>
              <option value="PT56H">56 hours</option>
              <option value="PT60H">60 hours</option>
              <option value="PT72H">72 hours</option>
              <option value="PT75H">75 hours</option>
              <option value="PT80H">80 hours</option>
              <option value="PT84H">84 hours</option>
              <option value="P4D">4 days</option>
              <option value="PT98H">98 hours</option>
              <option value="PT100H">100 hours</option>
              <option value="PT104H">104 hours</option>
              <option value="PT112H">112 hours</option>
              <option value="P5D">5 days</option>
              <option value="PT130H">130 hours</option>
              <option value="PT140H">140 hours</option>
              <option value="P6D">6 days</option>
              <option value="PT150H">150 hours</option>
              <option value="PT168H">168 hours</option>
              <option value="P7D">7 days</option>
              <option value="PT180H">180 hours</option>
              <option value="PT191H">191 hours</option>
              <option value="P8D">8 days</option>
              <option value="PT196H">196 hours</option>
              <option value="PT200H">200 hours</option>
              <option value="P9D">9 days</option>
              <option value="P10D">10 days</option>
              <option value="P12D">12 days</option>
              <option value="P13D">13 days</option>
              <option value="P14D">14 days</option>
              <option value="P15D">15 days</option>
              <option value="PT365H">365 hours</option>
              <option value="P20D">20 days</option>
              <option value="P21D">21 days</option>
              <option value="P22D">22 days</option>
              <option value="P23D">23 days</option>
              <option value="P24D">24 days</option>
              <option value="P25D">25 days</option>
              <option value="P28D">28 days</option>
              <option value="P29D">29 days</option>
              <option value="P30D">30 days</option>
            </select>
          </li>

          {#if freePeriod}
            <li>
              <label for="new-price-interval">Every</label>
              <select id="new-price-interval" name="interval">
                <option value="PT24H">24 hours</option>
                <option value="P1D">calendar day</option>
                <option value="PT48H">48 hours</option>
                <option value="PT60H">60 hours</option>
                <option value="PT72H">72 hours</option>
                <option value="P4D">4 days</option>
                <option value="P5D">5 days</option>
                <option value="P6D">6 days</option>
                <option value="P7D">7 days</option>
                <option value="P1W">calendar week</option>
                <option value="P8D">8 days</option>
                <option value="P9D">9 days</option>
                <option value="P10D">10 days</option>
                <option value="P11D">11 days</option>
                <option value="P12D">12 days</option>
                <option value="P13D">13 days</option>
                <option value="P14D">14 days</option>
                <option value="P15D">15 days</option>
                <option value="P21D">21 days</option>
                <option value="P28D">28 days</option>
                <option value="P29D">29 days</option>
                <option value="P30D">30 days</option>
                <option value="P1M">calendar month</option>
                <option value="P31D">31 days</option>
                <option value="P35D">35 days</option>
                <option value="P42D">42 days</option>
                <option value="P45D">45 days</option>
                <option value="P60D">60 days</option>
                <option value="P90D">90 days</option>
                <option value="P1Q">calendar quarter</option>
                <option value="P120D">120 days</option>
                <option value="P180D">180 days</option>
                <option value="P365D">365 days</option>
                <option value="P1Y">calendar year</option>
              </select>
            </li>
          {/if}
          <li>
            <label for="new-price-group">Per</label>
            <select id="new-price-group" bind:value={group} name="group">
              <option value="vehicle">vehicle</option>
              <option value="tenant">unit</option>
              <option value="principal">specific</option>
            </select>
          </li>
          {#if principal}
            <li>
              <label for="new-price-principal">Unit</label><select
                id="new-price-principal"
                name="principal"
              >
                <option value="">any</option>
                {#if $units}
                  {#each $units as item}
                    <option value={item.id}>{item.display}</option>
                  {/each}
                {/if}
              </select>
            </li>
          {/if}
        {:else if hasPrice}
          <li>
            <label for="new-price-contingent">Charge</label>
            <ul>
              <li>
                <input
                  id="new-price-contingent-false"
                  type="radio"
                  name="continent"
                  bind:group={contingent}
                  value={false}
                /><label for="new-price-contingent-false">usage fee</label>
              </li>
              <li>
                <input
                  id="new-price-contingent-true"
                  type="radio"
                  name="contingent"
                  bind:group={contingent}
                  value={true}
                /><label for="new-price-contingent-true"
                  >contingent/deposit</label
                >
              </li>
            </ul>
          </li>
          <li>
            <label for="new-price-title">Label</label>
            <input
              type="text"
              id="new-price-title"
              name="title"
              placeholder="optional"
            />
          </li>
          <li>
            <label for="new-price-maximum">Calculate</label>
            <ul>
              <li>
                <input
                  id="new-price-maximum-false"
                  type="radio"
                  name="maximum"
                  bind:group={maximum}
                  value={false}
                /><label for="new-price-type-false">charge</label>
              </li>
              <li>
                <input
                  id="new-price-maximum-true"
                  type="radio"
                  name="maximum"
                  bind:group={maximum}
                  value={true}
                /><label for="new-price-type-true">limit to max cap</label>
              </li>
            </ul>
          </li>
          {#if maximum}
            <li>
              <label for="new-price-charge">Limit to</label>
              <ul>
                <li>
                  <input
                    id="new-price-charge-max-24"
                    type="radio"
                    name="period"
                    bind:group={period}
                    value="PT24H"
                  /><label for="new-price-charge-max-24">max per 24 hours</label
                  >
                </li>
                <li>
                  <input
                    id="new-price-charge-max-12"
                    type="radio"
                    name="period"
                    bind:group={period}
                    value="PT12H"
                  /><label for="new-price-charge-max-12">max per 12 hours</label
                  >
                </li>
                <li>
                  <input
                    id="new-price-charge-max"
                    type="radio"
                    name="period"
                    bind:group={period}
                    value=""
                  /><label for="new-price-charge-max">max total</label>
                </li>
              </ul>
            </li>
          {:else}
            <li>
              <label for="new-price-charge">Charge</label>
              <ul>
                <li>
                  <input
                    id="new-price-charge-PT30M"
                    type="radio"
                    name="period"
                    bind:group={period}
                    value="PT30M"
                  /><label for="new-price-charge-PT30M">per 30m rate</label>
                </li>
                <li>
                  <input
                    id="new-price-charge-hourly"
                    type="radio"
                    name="period"
                    bind:group={period}
                    value="PT1H"
                  /><label for="new-price-charge-hourly">per hour rate</label>
                </li>
                <li>
                  <input
                    id="new-price-charge-24h"
                    type="radio"
                    name="period"
                    bind:group={period}
                    value="PT24H"
                  /><label for="new-price-charge-24h">per 24 hours rate</label>
                </li>
                <li>
                  <input
                    id="new-price-charge-12h"
                    type="radio"
                    name="period"
                    bind:group={period}
                    value="PT12H"
                  /><label for="new-price-charge-12h">per 12 hours rate</label>
                </li>
                <li>
                  <input
                    id="new-price-charge-30d"
                    type="radio"
                    name="period"
                    bind:group={period}
                    value="P30D"
                  /><label for="new-price-charge-30d">per 30 days rate</label>
                </li>
                <li>
                  <input
                    id="new-price-charge-flatrate"
                    type="radio"
                    name="period"
                    bind:group={period}
                    value=""
                  /><label for="new-price-charge-flatrate">fixed rate</label>
                </li>
              </ul>
            </li>
            {#if period === ""}
              <li>
                <label for="price-valid-duration-min">Min length</label>
                <select
                  id="price-valid-duration-min"
                  name="valid.duration.min"
                  bind:value={validDurationMin}
                >
                  <option value="">n/a</option>
                  {#each times.filter((time) => true || time < 24 * hourMS) as i}
                    <option value={formatDuration(i)}>{formatTime(i)}</option>
                  {/each}
                </select>
              </li>
              {#if validDurationMaxValues}
                <li>
                  <label for="price-valid-duration-max">Max length</label>
                  <select
                    id="price-valid-duration-max"
                    name="valid.duration.max"
                  >
                    {#each validDurationMaxValues as i}
                      <option value={formatDuration(i)}>{formatTime(i)}</option>
                    {/each}
                  </select>
                </li>
              {/if}
            {/if}
          {/if}
        {/if}

        <!-- <li><label for="new-price-concurrent">Type</label><select id="new-price-concurrent" name="period" on:change={e => { hourly = e.target.value === "PT1H"; daily = e.target.value === "PT24H"; }}  on:blur={e => { hourly = e.target.value === "PT1H"; daily = e.target.value === "PT24H"; }}>
                <option value="PT1H">hourly</option>
                <option value="PT24H">daily cap</option>
                <option value="">flat rate</option>
            </select></li> -->

        {#if hasPrice}
          <li>
            <label for="new-price-applies">Applies</label>
            <select id="new-price-applies" bind:value={applies}>
              <option value="">all days & times</option>
              <option value="partial">partial week</option>
              <option value="daystimes">specific days & times…</option>
            </select>
          </li>

          {#if "partial" === applies}
            <li>
              <label for="new-price-start">Start</label>
              <select id="new-price-startday" name="valid.day.min" required>
                <option value="">--</option>
                <option value="0">Sunday</option>
                <option value="1">Monday</option>
                <option value="2">Tuesday</option>
                <option value="3">Wednesday</option>
                <option value="4">Thursday</option>
                <option value="5">Friday</option>
                <option value="6">Saturday</option>
              </select><input
                id="new-price-starttime"
                name="valid.time.min"
                type="time"
                required
                placeholder="required"
              />
            </li>
            <li>
              <label for="new-price-end">End</label>
              <select id="new-price-endday" name="valid.day.max" required>
                <option value="">--</option>
                <option value="0">Sunday</option>
                <option value="1">Monday</option>
                <option value="2">Tuesday</option>
                <option value="3">Wednesday</option>
                <option value="4">Thursday</option>
                <option value="5">Friday</option>
                <option value="6">Saturday</option>
              </select><input
                id="new-price-endtime"
                name="valid.time.max"
                type="time"
                required
                placeholder="required"
              />
            </li>
          {:else if "daystimes" === applies}
            <li>
              <label for="new-price-days">Days</label>
              <select id="new-price-days" bind:value={days}>
                <option value={false}>every day</option>
                <option value={true}>specific days…</option>
              </select>
              {#if days}
                <ul>
                  <li>
                    <input
                      id="new-price-days-sun"
                      type="checkbox"
                      name="valid.days"
                      value="0"
                      checked
                    /><label for="new-price-days-sun">Sunday</label>
                  </li>
                  <li>
                    <input
                      id="new-price-days-mon"
                      type="checkbox"
                      name="valid.days"
                      value="1"
                      checked
                    /><label for="new-price-days-mon">Monday</label>
                  </li>
                  <li>
                    <input
                      id="new-price-days-tue"
                      type="checkbox"
                      name="valid.days"
                      value="2"
                      checked
                    /><label for="new-price-days-tue">Tuesday</label>
                  </li>
                  <li>
                    <input
                      id="new-price-days-wed"
                      type="checkbox"
                      name="valid.days"
                      value="3"
                      checked
                    /><label for="new-price-days-wed">Wednesday</label>
                  </li>
                  <li>
                    <input
                      id="new-price-days-thu"
                      type="checkbox"
                      name="valid.days"
                      value="4"
                      checked
                    /><label for="new-price-days-thu">Thursday</label>
                  </li>
                  <li>
                    <input
                      id="new-price-days-fri"
                      type="checkbox"
                      name="valid.days"
                      value="5"
                      checked
                    /><label for="new-price-days-fri">Friday</label>
                  </li>
                  <li>
                    <input
                      id="new-price-days-sat"
                      type="checkbox"
                      name="valid.days"
                      value="6"
                      checked
                    /><label for="new-price-days-sat">Saturday</label>
                  </li>
                </ul>
              {/if}
            </li>

            <li>
              <label for="new-price-starttime">Start time</label>
              <input
                id="new-price-starttime"
                name="valid.time.min"
                type="time"
                required
                placeholder="required"
              />
            </li>
            <li>
              <label for="new-price-endtime">End time</label>
              <input
                id="new-price-endtime"
                name="valid.time.max"
                type="time"
                required
                placeholder="required"
              />
            </li>
          {/if}
        {/if}
        <li>
          <label for="new-meter-tenant-subject">Policy</label>
          <select
            id="new-meter-tenant-subject"
            name="subject"
            value={subjects ? "" : policy.policy}
            on:change={(e) => (subjects = !e.target.value)}
          >
            <option value={policy.policy}>{policy.title} only</option>
            <option value="">select…</option>
          </select>
        </li>
        {#if subjects}
          <li>
            <ul>
              {#each $policies.filter((item) => item.amenity == policy.amenity) as item}
                <li>
                  <input
                    id="new-meter-tenant-subject-{item.policy}"
                    type="checkbox"
                    name="subject"
                    value={item.policy}
                    checked={item.policy == policy.policy}
                  /><label for="new-meter-tenant-subject-{item.policy}"
                    >{item.title}</label
                  >
                </li>
              {/each}
            </ul>
            <!-- <select id="new-meter-tenant-subject" name="subject" multiple>
              {#each $policies.filter(item => item.amenity == policy.amenity) as item}
              <option value="{item.policy}">{item.title}</option>
              {/each}
            </select> -->
          </li>
        {/if}
        <li>
          <label for="new-price-valid-interval"> Start </label>
          <select name="valid">
            <option value="">immediately</option>
            {#each Array.from( { length: 90 }, (v, i) => addDays(new Date(), i) ) as date}
              <option value={format(date, "yyyy-MM-dd'T'00:00:00'/'")}
                >{format(date, "EE MMM d")}</option
              >
            {/each}
          </select>
        </li>
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Create Price"}</button
      >
    </footer>
  </form>
{/if}
