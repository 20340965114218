<script>
  import { fetchCreatePolicyMeter } from "../api";
  import {
    parse,
    startOfDay,
    startOfHour,
    addDays,
    addMinutes,
  } from "date-fns";
  import { format } from "date-fns-tz";
  import { policies } from "../stores";

  export let policy = null;

  let applies = false;
  let days = false;
  let type = "time";
  let subjects = false;
  let minimum = false;

  $: count = type == "count";

  let submittable = false;
  let submitting = false;

  function change(e) {
    submittable = e.target.form.checkValidity();
  }

  function setDefaults() {
    days = applies = submitting = submittable = subjects = minimum = true;
    days = applies = submitting = submittable = subjects = minimum = false;
    (type = true) && (type = "time");
  }

  async function submit(e) {
    e.preventDefault();

    if (!policy || submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    await fetchCreatePolicyMeter(policy, formData);

    form.reset();

    setDefaults();
  }

  let scheduledInterval = [startOfHour(new Date()), startOfHour(new Date())];

  function startDate(e) {
    scheduledInterval[0] = parse(
      `${e.target.value}T${format(scheduledInterval[0], "HH:mm")}`,
      "yyyy-MM-dd'T'HH:mm",
      new Date(e.target.value)
    );
    scheduledInterval = scheduledInterval;
  }

  function startTime(e) {
    scheduledInterval[0] = parse(
      `${format(scheduledInterval[0], "yyyy-MM-dd")}T${e.target.value}`,
      "yyyy-MM-dd'T'HH:mm",
      scheduledInterval[0]
    );
    scheduledInterval = scheduledInterval;
  }

  function endDate(e) {
    scheduledInterval[1] = parse(
      `${e.target.value}T${format(scheduledInterval[1], "HH:mm")}`,
      "yyyy-MM-dd'T'HH:mm",
      new Date(e.target.value)
    );
    scheduledInterval = scheduledInterval;
  }

  function endTime(e) {
    scheduledInterval[1] = parse(
      `${format(scheduledInterval[1], "yyyy-MM-dd")}T${e.target.value}`,
      "yyyy-MM-dd'T'HH:mm",
      scheduledInterval[1]
    );
    scheduledInterval = scheduledInterval;
  }
</script>

{#if policy}
  <form on:change={change} on:input={change} on:submit={submit}>
    {#if "valid" === applies}
      <input
        type="hidden"
        name="valid"
        value="{format(scheduledInterval[0], "yyyy-MM-dd'T'HH:mm:ssXXX", {
          timeZone: policy.timezone,
        })}/{format(scheduledInterval[1], "yyyy-MM-dd'T'HH:mm:ssXXX", {
          timeZone: policy.timezone,
        })}"
      />
    {/if}
    <input type="hidden" name="principal" value="vehicle" />
    <fieldset>
      <ul>
        <li>
          <label for="new-meter-vehicle-minmax">Type</label>
          <select
            name="minimum"
            id="new-meter-vehicle-minmax"
            value={(minimum && "true") || ""}
            on:change={(e) => (minimum = e.target.value == "true")}
          >
            <option value="">Limit</option>
            <option value="true">Report</option>
          </select>
        </li>
        {#if minimum}
          <li>
            <label for="new-meter-vehicle-type">Report</label>
            <select
              name="minimum"
              id="new-meter-vehicle-type"
              bind:value={type}
            >
              <option value="time">min time</option>
              <option value="count">min count</option>
            </select>
          </li>
        {:else}
          <li>
            <label for="new-meter-vehicle-type">Limit</label>
            <ul>
              <li>
                <input
                  id="new-meter-vehicle-type-time"
                  type="radio"
                  bind:group={type}
                  value="time"
                /><label for="new-meter-vehicle-type-time">max time</label>
              </li>
              <li>
                <input
                  id="new-meter-vehicle-type-count"
                  type="radio"
                  bind:group={type}
                  value="count"
                /><label for="new-meter-vehicle-type-count">max count</label>
              </li>
            </ul>
          </li>
        {/if}
        {#if count}
          <li>
            <label for="new-meter-vehicle-maximum"
              >{minimum ? "At or above" : "To"}</label
            ><input
              id="new-meter-vehicle-maximum"
              name="count"
              type="number"
              min="1"
              required
              placeholder="required"
              value="1"
            />
          </li>
          <li>
            <label for="new-meter-vehicle-count-interval">Count</label>
            <ul>
              <li>
                <input
                  id="new-meter-vehicle-count-interval"
                  type="radio"
                  name="count.start"
                  value="false"
                  checked
                /><label for="new-meter-vehicle-count-interval"
                  >permit start to end</label
                >
              </li>
              <li>
                <input
                  id="new-meter-vehicle-count-start"
                  type="radio"
                  name="count.start"
                  value="true"
                /><label for="new-meter-vehicle-count-start"
                  >permit start only</label
                >
              </li>
            </ul>
          </li>
        {:else}
          <li>
            <label for="new-meter-vehicle-maximum"
              >{minimum ? "At or above" : "To"}</label
            ><select id="new-meter-vehicle-maximum" name="time"
              ><option value="PT5M">5 minutes</option><option value="PT10M"
                >10 minutes</option
              ><option value="PT15M">15 minutes</option><option value="PT30M"
                >30 minutes</option
              >
              <option value="PT1H">1 hour</option>
              <option value="PT90M">90 minutes</option>
              <option value="PT2H">2 hours</option>
              <option value="PT3H">3 hours</option>
              <option value="PT4H">4 hours</option>
              <option value="PT5H">5 hours</option>
              <option value="PT6H">6 hours</option>
              <option value="PT8H">8 hours</option>
              <option value="PT10H">10 hours</option>
              <option value="PT12H">12 hours</option>
              <option value="PT14H">14 hours</option>
              <option value="PT16H">16 hours</option>
              <option value="PT18H">18 hours</option>
              <option value="PT20H">20 hours</option>
              <option value="PT21H">21 hours</option>
              <option value="PT22H">22 hours</option>
              <option value="PT23H">23 hours</option>
              <option value="PT24H">24 hours</option>
              <option value="PT25H">25 hours</option>
              <option value="PT27H">27 hours</option>
              <option value="PT28H">28 hours</option>
              <option value="PT30H">30 hours</option>
              <option value="PT36H">36 hours</option>
              <option value="PT40H">40 hours</option>
              <option value="PT42H">42 hours</option>
              <option value="PT48H">48 hours</option>
              <option value="PT50H">50 hours</option>
              <option value="PT56H">56 hours</option>
              <option value="PT60H">60 hours</option>
              <option value="PT63H">63 hours</option>
              <option value="PT70H">70 hours</option>
              <option value="PT72H">72 hours</option>
              <option value="PT75H">75 hours</option>
              <option value="PT80H">80 hours</option>
              <option value="PT84H">84 hours</option>
              <option value="PT90H">90 hours</option>
              <option value="PT96H">96 hours</option>
              <option value="P4D">4 days</option>
              <option value="PT98H">98 hours</option>
              <option value="PT100H">100 hours</option>
              <option value="PT104H">104 hours</option>
              <option value="PT105H">105 hours</option>
              <option value="PT112H">112 hours</option>
              <option value="P5D">5 days</option>
              <option value="PT140H">140 hours</option>
              <option value="P6D">6 days</option>
              <option value="PT150H">150 hours</option>
              <option value="PT168H">168 hours</option>
              <option value="P7D">7 days</option>
              <option value="PT180H">180 hours</option>
              <option value="P8D">8 days</option>
              <option value="PT196H">196 hours</option>
              <option value="PT200H">200 hours</option>
              <option value="PT220H">220 hours</option>
              <option value="P9D">9 days</option>
              <option value="P10D">10 days</option>
              <option value="P12D">12 days</option>
              <option value="PT300H">300 hours</option>
              <option value="P13D">13 days</option>
              <option value="P14D">14 days</option>
              <option value="P15D">15 days</option>
              <option value="P16D">16 days</option>
              <option value="P18D">18 days</option>
              <option value="P20D">20 days</option>
              <option value="P21D">21 days</option>
              <option value="P22D">22 days</option>
              <option value="P23D">23 days</option>
              <option value="P24D">24 days</option>
              <option value="P25D">25 days</option>
              <option value="P28D">28 days</option>
              <option value="P29D">29 days</option>
              <option value="PT700H">700 hours</option>
              <option value="P30D">30 days</option>
              <option value="P31D">31 days</option>
              <option value="P35D">35 days</option>
              <option value="P42D">42 days</option>
              <option value="P45D">45 days</option>
              <option value="P60D">60 days</option>
              <option value="P62D">62 days</option>
              <option value="PT{24 * 70}H">70 days</option>
              <option value="PT{24 * 84}H">84 days</option>
              <option value="P90D">90 days</option>
              <option value="P96D">96 days</option>
              <option value="P120D">120 days</option>
              <option value="P180D">180 days</option></select
            >
          </li>
        {/if}
        <li>
          <label for="new-meter-vehicle-period">Every</label><select
            id="new-meter-vehicle-period"
            name="period"
            ><option value="PT24H">24 hours</option>
            <option value="P1D">calendar day</option>
            <option value="PT48H">48 hours</option>
            <option value="PT60H">60 hours</option>
            <option value="PT72H">72 hours</option>
            <option value="P4D">4 days</option>
            <option value="P5D">5 days</option>
            <option value="P6D">6 days</option>
            <option value="P7D">7 days</option>
            <option value="P1W">calendar week</option>
            <option value="P8D">8 days</option>
            <option value="P9D">9 days</option>
            <option value="P10D">10 days</option>
            <option value="P11D">11 days</option>
            <option value="P12D">12 days</option>
            <option value="P13D">13 days</option>
            <option value="P14D">14 days</option>
            <option value="P15D">15 days</option>
            <option value="P17D">17 days</option>
            <option value="P21D">21 days</option>
            <option value="P25D">25 days</option>
            <option value="P28D">28 days</option>
            <option value="P29D">29 days</option>
            <option value="P30D">30 days</option>
            <option value="P1M">calendar month</option>
            <option value="P31D">31 days</option>
            <option value="P35D">35 days</option>
            <option value="P42D">42 days</option>
            <option value="P45D">45 days</option>
            <option value="P60D">60 days</option>
            <option value="P90D">90 days</option>
            <option value="P1Q">calendar quarter</option>
            <option value="P120D">120 days</option>
            <option value="P180D">180 days</option>
            <option value="P365D">365 days</option>
            <option value="P1Y">calendar year</option></select
          >
        </li>
        <li>
          <label for="new-meter-vehicle-applies">Applies</label>
          <select
            id="new-meter-vehicle-applies"
            on:change={(e) => (applies = e.target.value)}
            on:blur={(e) => (applies = e.target.value)}
          >
            <option value="">all days & times</option>
            <option value="valid">specific start & end</option>
            <option value="partial">partial week…</option>
            <option value="daystimes">specific days & times…</option>
          </select>
        </li>
        {#if "partial" === applies}
          <li>
            <label for="new-meter-vehicle-start">Start</label>
            <select
              id="new-meter-vehicle-startday"
              name="valid.day.min"
              required
            >
              <option value="">--</option>
              <option value="0">Sunday</option>
              <option value="1">Monday</option>
              <option value="2">Tuesday</option>
              <option value="3">Wednesday</option>
              <option value="4">Thursday</option>
              <option value="5">Friday</option>
              <option value="6">Saturday</option>
            </select><input
              id="new-meter-vehicle-starttime"
              name="valid.time.min"
              type="time"
              required
              placeholder="required"
            />
          </li>
          <!-- <li><label for="new-meter-vehicle-starttime">Start time</label></li> -->
          <li>
            <label for="new-meter-vehicle-end">End</label>
            <select id="new-meter-vehicle-endday" name="valid.day.max" required>
              <option value="">--</option>
              <option value="0">Sunday</option>
              <option value="1">Monday</option>
              <option value="2">Tuesday</option>
              <option value="3">Wednesday</option>
              <option value="4">Thursday</option>
              <option value="5">Friday</option>
              <option value="6">Saturday</option>
            </select><input
              id="new-meter-vehicle-endtime"
              name="valid.time.max"
              type="time"
              required
              placeholder="required"
            />
          </li>
          <!-- <li><label for="new-meter-vehicle-endtime">End time</label></li> -->
        {:else if "daystimes" === applies}
          <li>
            <label for="new-meter-vehicle-days">Days</label>
            <select
              id="new-meter-vehicle-days"
              on:change={(e) => (days = e.target.value === "true")}
              on:blur={(e) => (days = e.target.value === "true")}
            >
              <option value="false">every day…</option>
              <option value="true">specific days…</option>
            </select>
            {#if days}
              <ul>
                <li>
                  <input
                    id="new-meter-vehicle-days-sun"
                    type="checkbox"
                    name="valid.days"
                    value="0"
                    checked
                  /><label for="new-meter-vehicle-days-sun">Sunday</label>
                </li>
                <li>
                  <input
                    id="new-meter-vehicle-days-mon"
                    type="checkbox"
                    name="valid.days"
                    value="1"
                    checked
                  /><label for="new-meter-vehicle-days-mon">Monday</label>
                </li>
                <li>
                  <input
                    id="new-meter-vehicle-days-tue"
                    type="checkbox"
                    name="valid.days"
                    value="2"
                    checked
                  /><label for="new-meter-vehicle-days-tue">Tuesday</label>
                </li>
                <li>
                  <input
                    id="new-meter-vehicle-days-wed"
                    type="checkbox"
                    name="valid.days"
                    value="3"
                    checked
                  /><label for="new-meter-vehicle-days-wed">Wednesday</label>
                </li>
                <li>
                  <input
                    id="new-meter-vehicle-days-thu"
                    type="checkbox"
                    name="valid.days"
                    value="4"
                    checked
                  /><label for="new-meter-vehicle-days-thu">Thursday</label>
                </li>
                <li>
                  <input
                    id="new-meter-vehicle-days-fri"
                    type="checkbox"
                    name="valid.days"
                    value="5"
                    checked
                  /><label for="new-meter-vehicle-days-fri">Friday</label>
                </li>
                <li>
                  <input
                    id="new-meter-vehicle-days-sat"
                    type="checkbox"
                    name="valid.days"
                    value="6"
                    checked
                  /><label for="new-meter-vehicle-days-sat">Saturday</label>
                </li>
              </ul>
            {/if}
          </li>

          <!-- <li><label for="new-meter-vehicle-days"></label>
                
               <select id="new-meter-vehicle-days" name="valid.days" multiple>
                    <option value="0" selected>Sunday</option>
                    <option value="1" selected>Monday</option>
                    <option value="2" selected>Tuesday</option>
                    <option value="3" selected>Wednesday</option>
                    <option value="4" selected>Thursday</option>
                    <option value="5" selected>Friday</option>
                    <option value="6" selected>Saturday</option>
                </select>
            </li> -->

          <li>
            <label for="new-meter-vehicle-starttime">Start time</label>
            <input
              id="new-meter-vehicle-starttime"
              name="valid.time.min"
              type="time"
              required
              placeholder="required"
            />
          </li>
          <!-- <li><label for="new-meter-vehicle-starttime">Start time</label></li> -->
          <li>
            <label for="new-meter-vehicle-endtime">End time</label>
            <input
              id="new-meter-vehicle-endtime"
              name="valid.time.max"
              type="time"
              required
              placeholder="required"
            />
          </li>
        {:else if "valid" === applies}
          <li>
            <label>Starting</label><select
              value={format(scheduledInterval[0], "yyyy-MM-dd")}
              on:blur={startDate}
              on:change={startDate}
            >
              {#each Array.from( { length: 30 }, (v, i) => addDays(new Date(), i) ) as date}
                <option value={format(date, "yyyy-MM-dd")}
                  >{format(date, "EE MMM d")}</option
                >
              {/each}
            </select><select
              value={format(scheduledInterval[0], "HH:mm")}
              on:blur={startTime}
              on:change={startTime}
            >
              {#each Array.from( { length: 48 }, (v, i) => addMinutes(startOfDay(new Date()), i * 30) ) as date}
                <option value={format(date, "HH:mm")}
                  >{format(date, "h:mm a")}</option
                >
              {/each}
            </select>
          </li>
          <li>
            <label>Ending</label><select
              value={format(scheduledInterval[1], "yyyy-MM-dd")}
              on:blur={endDate}
              on:change={endDate}
            >
              {#each Array.from( { length: 30 }, (v, i) => addDays(new Date(), i) ) as date}
                <option value={format(date, "yyyy-MM-dd")}
                  >{format(date, "EE MMM d")}</option
                >
              {/each}
            </select><select
              value={format(scheduledInterval[1], "HH:mm")}
              on:blur={endTime}
              on:change={endTime}
            >
              {#each Array.from( { length: 48 }, (v, i) => addMinutes(startOfDay(new Date()), i * 30) ) as date}
                <option value={format(date, "HH:mm")}
                  >{format(date, "h:mm a")}</option
                >
              {/each}
            </select>
          </li>
        {/if}
        <li>
          <label for="new-meter-vehicle-issued">Permits</label>
          <select id="new-meter-vehicle-issued" name="issued.future">
            <option value="">count already created</option>
            <option value="true">only count new</option>
          </select>
        </li>
        <li>
          <label for="new-meter-vehicle-effective">Time</label>
          <select id="new-meter-vehicle-effective" name="effective.future">
            <option value="">include already used</option>
            <option value="true">reset starting now</option>
          </select>
        </li>
        <li>
          <label for="new-meter-vehicle-title">Reason</label><input
            id="new-meter-vehicle-title"
            name="title"
            type="text"
            maxlength="200"
            placeholder="optional"
          />
        </li>
        <li>
          <label for="new-meter-tenant-subject">Policy</label>
          <select
            id="new-meter-tenant-subject"
            name="subject"
            value={subjects ? "" : policy.policy}
            on:change={(e) => (subjects = !e.target.value)}
          >
            <option value={policy.policy}>{policy.title} only</option>
            <option value="">select…</option>
          </select>
        </li>
        {#if subjects}
          <li>
            <ul>
              {#each $policies.filter((item) => item.amenity == policy.amenity) as item}
                <li>
                  <input
                    id="new-meter-tenant-subject-{item.policy}"
                    type="checkbox"
                    name="subject"
                    value={item.policy}
                    checked={item.policy == policy.policy}
                  /><label for="new-meter-tenant-subject-{item.policy}"
                    >{item.title}</label
                  >
                </li>
              {/each}
            </ul>
            <!-- <select id="new-meter-tenant-subject" name="subject" multiple>
              {#each $policies.filter(item => item.amenity == policy.amenity) as item}
              <option value="{item.policy}">{item.title}</option>
              {/each}
            </select> -->
          </li>
        {/if}
        <li>
          <label for="new-meter-tenant-valid-interval"> Start </label>
          <select name="valid">
            <option value="">immediately</option>
            {#each Array.from( { length: 30 }, (v, i) => addDays(new Date(), i) ) as date}
              <option value={format(date, "yyyy-MM-dd'T'00:00:00'/'")}
                >{format(date, "EE MMM d")}</option
              >
            {/each}
          </select>
        </li>
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Create Meter"}</button
      >
    </footer>
  </form>
{/if}
